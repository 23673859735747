
import React, { useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
const NSCardButton = ({options,callBack}) =>{
    const { 
        title, 
        icon, 
        iconOptional,  
        horizontal 
    } = options
    
    const handlerOnClick = () => {  
        if(callBack){ 
            callBack()
        }
    } 
    return (<>
        <div onClick={handlerOnClick}  
            className={`p-1 h-full w-full cursor-pointer bg-white mt-[1%] rounded-[10px] w-full flex flex-col items-center`}>
            <div className={`text-center flex p-1 items-center gap-0  h-full w-full ${horizontal?'justify-between px-6':'justify-between flex-col items-between'}`}> 
                <div  className={` ${horizontal?'w-auto':'w-4/6'} flex flex-col p-1 justify-center items-between w-4/6 font-[500]`}>
                    {title} 
                </div>
                <div className="flex items-center justify-center w-2/6 rounded bg-[#fff] w-[40px] h-[40px]">
                    <img src={icon} alt={icon} />
                    {   iconOptional 
                        &&  
                        <img  
                            src={iconOptional} 
                            alt={iconOptional} 
                        />
                    }
                </div>
            </div>
            
        </div>
    </>) 
}

export default NSCardButton