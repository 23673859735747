import React, { useState } from 'react'
import { PRIMARY_COLOR } from "../../../helpers/Constants" 
import { isMobile,isDesktop,isIPad13 } from 'react-device-detect'

const Boolean = ({onClick,forceDisable,forceStop, title, icon, initialState,noChecker}) => {
    
    const [ status, setStatus ] = useState(initialState) 
    const handlerStatus = () => { 
        if(forceStop) return
        if(forceDisable) return
        setStatus(!status)
        if(onClick){
            onClick(!status)
        }
    }  
    return (<>
        <div 
        onClick={handlerStatus}  
        style={{
            opacity:forceDisable?'50%':'100%', 
            background:status?PRIMARY_COLOR:'#fff',
            border:status?'solid 2px #fff':'solid 2px #0001',
            width: isIPad13 ? '31%' : isMobile ? '47%' : '31%'
        }}
        className={` cursor-pointer bg-white mt-[1%] rounded-[10px] flex flex-col items-center `}>
            <div className="flex p-1 items-center gap-2 justify-between w-full"> 
                <div  style={{color: !status?'black':'#fff',}} className="text-sm flex flex-col p-1 justify-center items-between w-4/6 font-[600]">
                    {title} 
                </div>
                <div className="flex items-center justify-center w-2-6 rounded bg-[#fff] w-[40px] h-[40px]">
                    <img src={icon} alt={icon} />
                </div>
            </div> 
            <div className="flex p-1 items-center justify-between gap-2 w-full"> 
                <div className="flex p-1 items-center w-4/6">
                    { !noChecker &&
                        <input 
                            disabled={forceDisable} 
                            style={{accentColor:status ? PRIMARY_COLOR :'#fff'}} 
                            type='checkbox' 
                            className=' h-[18px] w-[18px]' 
                            checked={status} 
                            />
                    }
                </div> 
            </div> 
        </div> 
    </>) 
}

export default Boolean