import React, { useState,useEffect, useContext } from 'react'
import './basic.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-tabs/style/react-tabs.css' 
import { isMobile,isDesktop,isTablet } from 'react-device-detect' 
import Header from '../../components/Header/Header'
import NSSwitcher from '../../components/NSButton/Types/Switcher'
import NSBoolean from '../../components/NSButton/Types/Boolean' 
import NSTimer from '../../components/NSButton/Types/Timer'
import NSCounter from '../../components/NSButton/Types/Counter'
import NSIndicator from '../../components/NSButton/Types/Indicator'
import NSSimpleButton from '../../components/NSButton/Types/SimpleButton' 
import {  
    iconAdrenalina, 
    iconAmiodarona, 
    iconAvisaEquipo, 
    iconCausaParada, 
    iconCompresiones, 
    iconDea, 
    iconDesfibrilacion, 
    iconMonitor, 
    iconNoResponde, 
    iconNotas, 
    iconRitmoEcg, 
    iconTecnicas, 
    iconVentilaciones,
    iconReanimacion, 
    iconPulmones,
    iconAdulto,
    iconViaAerea,
    logoBasico,
    
} from '../../helpers/ImagesCache'
import DialogVentilaciones from '../../components/DialogVentilaciones/DialogVentilaciones'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { GlobalContext,TimelineContext } from '../../helpers/Context'
import { TimelineDefault } from '../../helpers/Constants'
import DialogDownNotes from '../../components/DialogDownNotes/DialogDownNotes'
import axios from 'axios';
import { BASEURLAPI } from '../../helpers/Constants';

const Basic = () => {

    const contextGlobal = useContext( GlobalContext ); 
    const ctxTimeline = useContext( TimelineContext );
    const [userId, setUserId ] = useState(2)
    const [ForceStopCase, setForceStopCase] = useState(false)
    const [isStarted, setIsStarted] = useState(false) 
    const [canGetReport, setCanGetReport] = useState(false) 
    const [timerCounter, setTimerCounter] = useState(0)
        
    const [isPopupActive, setIsPopupActive] = useState(true) 
    const [isPopupNotasActive, setIsPopupNotasActive] = useState(false) 
    const [compresions, setCompresions] = useState(false) 

    const [notas, setNotas] = useState('')

     
    const SaveInforme = () => { 
        const url = `${BASEURLAPI}/createCase`
        const formData = new FormData()
        formData.append('userId',userId)
        formData.append('name',`TESTCASE-${+new Date()} (Basico)` )
        formData.append('timeline',JSON.stringify(ctxTimeline) )
        formData.append('duration',0)
        axios.post(url, formData )
        .then((response)=>{
            if(response.status == 200){
                console.log(response.data)
            }
            //setIsLoading(false)
        })
        .catch((error)=>{
            console.log('hubo un error',error)
            //setIsLoading(false)
        })
    }



    const handlerStart = () => setIsStarted(true)
    const handlerStop = () => setIsStarted(false) 
    const handlerForceStopCase = (value) => setForceStopCase(value) 
    useEffect(() => { setNotas(ctxTimeline.Notas) }, [ctxTimeline.Notas]) 
    useEffect(()=>{ 
        ctxTimeline.ECG = []
        ctxTimeline.Notas = ''
        ctxTimeline.CausaH = [] 
        ctxTimeline.causaT = []  
        ctxTimeline.Tecnicas = []  
        ctxTimeline.Farmacos = []   
    },[])
    useEffect(()=>{ 
        
        setNotas(ctxTimeline.Notas)  

    },[ctxTimeline.Notas])

    useEffect(()=>{
        if(canGetReport){

        }
    },[canGetReport])

    useEffect(()=>{
        if(timerCounter > 0){
            setCanGetReport(true)
        } else {
            setCanGetReport(false)
        }
    },[isStarted])

    const handlerCompresions = () => {
        setIsPopupActive(false)
        setCompresions(true)
        //realizar la funcion de activar compresiones y ventilaciones
    }
    const handlerCloseDialog = () => {
        setIsPopupActive(false) 
    }

    const handlerNotasDialog = () => {
        setIsPopupNotasActive(!isPopupNotasActive) 
    }

    useEffect(()=>{
        //ctxTimeline = TimelineDefault
        ctxTimeline.history = []
    },[])

    return (<>
         <div  style={styles.main} >
            <Header options={{}} minimal={true} logo={logoBasico}  />
            <div className='flex gap-8 my-3'> 
                <NSTimer  
                    milliseconds={true} 
                    options={ { titleOn: 'Parar caso', titleOff : 'Iniciar caso' }}
                    onStartTimer={(value)=>{   
                        ctxTimeline.startCase = value?.startAt 
                        ctxTimeline.history.push({
                            time: value?.startAt,
                            title:'Inicio de Caso',
                            object:'startCase'
                        })
                        handlerStart()
                    }} 
                    onStopTimer={(value)=>{ 
                        handlerForceStopCase(true)
                        ctxTimeline.startCase = value?.startAt 
                        ctxTimeline.stopCase =  value?.stopAt 
                        ctxTimeline.history.push({
                            time: value?.stopAt,
                            title:'Fin del Caso',
                            object:'stopCase'
                        })
                        handlerStop()
                        
                    }} 
                    />
                <NSTimer  
                    milliseconds={true}     
                    options={
                        { 
                            titleOn:'RCE',
                            titleOff:'Inicio PCR'
                        }} 
                    onStopTimer={(value)=>{ 
                        ctxTimeline.RCE.push(
                            {
                            start: value?.startAt,
                            stop: value?.stopAt
                            } 
                        ) 
                        ctxTimeline.history.push({
                            time: value?.stopAt,
                            title:'RCE',
                            object:'RCE'
                        })
                    }}
                    onStartTimer={(value)=>{  
                        ctxTimeline.PCR.push(
                            {
                            start: value?.startAt 
                            } 
                        ) 
                        ctxTimeline.history.push({
                            time: value?.startAt ,
                            title:'Inicio PCR',
                            object:'PCR'
                        })
                    }} 
                    forceDisable={!isStarted}
                    forceStop={ForceStopCase}
                    /> 
            </div> 
            
            <div  className='w-full'  style={styles.bodyContent} >
                <div className="p-2 w-full  h-full" style={styles.switcherSection}> 
                    <div className="w-10/12 flex justify-between w-full "> 
                        <NSSwitcher 
                            options={{
                                title:'Escenario seguro',
                                subtitleActive:'',
                                subtitle:'',
                                initialState:false 
                            }}  
                            onClick={(value)=>{  
                                ctxTimeline.isSafeScene = value
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`El escenario ${ value ? 'si ' : 'no ' }es seguro`,
                                    object:'isSafeScene'
                                })
                            }} 
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSSwitcher 
                            options={{
                                title:'Grita ayuda',
                                subtitleActive:'',
                                subtitle:'',
                                initialState:false 
                            }}  
                            onClick={(value)=>{  
                                ctxTimeline.shoutedHelp = value
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`${ value ? 'Si' : 'No' } Grita ayuda`,
                                    object:'shoutedHelp'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                         <NSSwitcher 
                            options={{
                                title:'Canula de Guedel',
                                subtitleActive:'',
                                subtitle:'',
                                initialState:false 
                            }}  
                            onClick={(value)=>{  
                                ctxTimeline.guedelCannula = value
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`${ value ? 'Activa' : 'Deshabilita' } Cannula de guedel`,
                                    object:'guedelCannula'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        /> 
                    </div>
                    <div className="h-full" style={styles.buttonsSeccion}  >
                        <NSBoolean 
                            title='Comprueba consciencia'
                            icon={iconNoResponde}
                            initialState={false} 
                            onClick={(value)=>{   
                                if(value == true){
                                    ctxTimeline.consistency = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`${ value ? 'Activa' : 'Deshabilita' } Comprueba Consciencia`,
                                    object:'consistency'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSCounter  
                            options={{
                                titleStart:'Abre via aerea',
                                titleOff:'Abre via aerea',
                                titleOn:'Abre via aerea',
                                icon:iconViaAerea,
                                initialState:false,
                                type:'restart'
                            }}  
                            onStopTimer={(value)=>{ 
                                ctxTimeline.openAirWay.push(
                                    {
                                     start: value?.startAt,
                                     stop: value?.stopAt
                                    } 
                                ) 
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title:`Detiene Abre via respiratoria`,
                                    object:'openAirWay'
                                })
                            }} 
                            onStartTimer={(value)=>{
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title:`Abre via respiratoria`,
                                    object:'openAirWay'
                                })
                            }}
                            list = {ctxTimeline.openAirWay}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSCounter  
                            options={{
                                titleStart:'Comprueba ventilacion',
                                titleOff:'Comprueba ventilacion',
                                titleOn:'Comprueba ventilacion', 
                                icon:iconPulmones,
                                initialState:false
                            }}   
                            onStopTimer={(value)=>{ 
                                ctxTimeline.checkVentilation.push(
                                    {
                                     start: value?.startAt,
                                     stop: value?.stopAt
                                    } 
                                ) 
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title:`Detiene Comprueba ventilacion`,
                                    object:'checkVentilation'
                                })
                            }} 
                            onStartTimer={(value)=>{
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title:`Inicia Comprueba ventilacion`,
                                    object:'checkVentilation'
                                })
                            }}
                            list = {ctxTimeline.checkVentilation}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSBoolean  
                            title='Avisa equipo de emergencias'
                            icon={iconAvisaEquipo}
                            initialState={false} 
                            onClick={(value)=>{   
                                if(value == true){
                                    ctxTimeline.notifiesEmergencyTeam = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`${ value ? 'Activa' : 'Deshabilita' } Notificar equipo emergencia`,
                                    object:'notifiesEmergencyTeam'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                         
                         
                        { 
                            compresions && 
                            <NSCounter 
                                options={{
                                    titleStart:'Inicia compresiones',
                                    titleOff:'Inicia compresiones',
                                    titleOn:'Detiene compresiones',
                                    icon:iconCompresiones,
                                    initialState:false
                                }}  
                                onStopTimer={(value)=>{ 
                                    ctxTimeline.compressions.push(
                                        {
                                         start: value?.startAt,
                                         stop: value?.stopAt
                                        } 
                                    ) 
                                    ctxTimeline.history.push({
                                        time: value?.stopAt,
                                        title:`Detiene Compresiones`,
                                        object:'compressions'
                                    })
                                }} 
                                onStartTimer={(value)=>{
                                    ctxTimeline.history.push({
                                        time: value?.startAt,
                                        title:`Inicia Compresiones`,
                                        object:'compressions'
                                    })
                                }}
                                list = {ctxTimeline.compressions}
                                forceDisable={!isStarted}
                                forceStop={ForceStopCase}
                            />
                        }
                        {
                            compresions &&
                            <NSCounter  
                                options={{
                                    titleStart:'Inicia ventilaciones',
                                    titleOff:'Inicia ventilaciones',
                                    titleOn:'Detiene ventilaciones',
                                    icon:iconVentilaciones,
                                    initialState:false
                                }}  
                                onStopTimer={(value)=>{ 
                                    ctxTimeline.vents.push(
                                        {
                                         start: value?.startAt,
                                         stop: value?.stopAt
                                        } 
                                    ) 
                                    ctxTimeline.history.push({
                                        time: value?.stopAt,
                                        title:`Detiene Ventilaciones`,
                                        object:'vents'
                                    })
                                }} 
                                onStartTimer={(value)=>{
                                    ctxTimeline.history.push({
                                        time: value?.startAt,
                                        title:`Inicia Ventilaciones`,
                                        object:'vents'
                                    })
                                }}
                                list = {ctxTimeline.vents}
                                forceDisable={!isStarted}
                                forceStop={ForceStopCase}
                            />
                        }
                        {
                            !compresions &&  
                            <NSCounter  
                                options={{
                                    titleStart:'Inicia RCP',
                                    titleOff:'Inicia RCP',
                                    titleOn:'Detiene RCP',
                                    icon:iconReanimacion,
                                    initialState:false 
                                }}  
                                onStopTimer={(value)=>{ 
                                    ctxTimeline.RCP.push(
                                        {
                                        start: value?.startAt,
                                        stop: value?.stopAt
                                        } 
                                    ) 
                                    ctxTimeline.history.push({
                                        time: value?.stopAt,
                                        title:`Detiene RCP`,
                                        object:'RCP'
                                    })
                                }} 
                                onStartTimer={(value)=>{
                                    ctxTimeline.history.push({
                                        time: value?.startAt,
                                        title:`Inicia RCP`,
                                        object:'RCP'
                                    })
                                }}
                                list={ctxTimeline.RCP}
                                forceDisable={!isStarted}
                                forceStop={ForceStopCase}
                            /> 
                        }
                        <NSCounter  
                            options={{
                                titleStart:'Coloca DEA',
                                titleOff:'DESCARGA',
                                titleOn:'DESCARGA',
                                icon:iconDea,
                                initialState:false
                            }}  
                            onStopTimer={(value)=>{ 
                                ctxTimeline.DEA.push(
                                    {
                                     start: value?.startAt,
                                     stop: value?.stopAt
                                    } 
                                ) 
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title:`DESCARGA`,
                                    object:'DEA'
                                })
                            }} 
                            onStartTimer={(value)=>{
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title:`Coloca DEA`,
                                    object:'DEA'
                                })
                            }}
                            list={ctxTimeline.DEA}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSBoolean 
                            title = 'Posicion lateral de seguridad'
                            icon = {null}
                            initialState = {false} 
                            onClick={(value)=>{   
                                if(value == true){
                                    ctxTimeline.lateralSafetyPosition = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`${ value ? 'Activa' : 'Deshabilita' } Posicion lateral de seguridad`,
                                    object:'lateralSafetyPosition'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />  
                        <NSIndicator
                             options={{
                                title:'Notas',
                                icon:iconNotas,
                                values:  [
                                    {status: notas == '' ? false : true },
                                ] ,
                                minimal:true,
                                initialState:true
                            }}  
                            onClick={handlerNotasDialog}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />   
                    </div>
                </div>
            </div>
            <div  className='w-full' style={styles.footer} >   
                <NSSimpleButton
                    options={{ title:'Informe' }}  
                    initialState={ForceStopCase}
                    callBack={SaveInforme}  
                    />  
            </div>
        </div>
        <DialogVentilaciones 
            text={'Desea medir compresiones y ventilaciones?'} 
            icon={faStopwatch} 
            callbackConfirm={handlerCompresions}
            callbackCancel={handlerCloseDialog}
            status={isPopupActive}
            /> 
        <DialogDownNotes 
            text={'Notas'} 
            icon={iconNotas} 
            callbackConfirm = {handlerNotasDialog}
            callbackCancel={handlerNotasDialog}
            status={isPopupNotasActive}  
            /> 
    </>
    )
}
export default Basic

const styles = {
    main:{
        maxWidth: isTablet ? '100%': isDesktop ? '1280px' : '700px',
        maxHeight:'100vh',
        position:'fixed',
        left:0,
        right:0,
        top:0,
        overflow:'hidden',
        display:'flex',
        flexDirection:'column',
        width:'100%', 
        justifyContent:'center',
        alignItems:'center'  , 
        marginRight:'auto',
        marginLeft: 'auto'
       
    },
    bodyContent: { 
        display:'flex',
        zIndex: 0 ,
        background: '#eaf7f5',
        overflow: 'hidden',
        justifyContent:'center', 
        height:'100%',
        minHeight:'80vh'
    },
    switcherSection:{
        display:'flex', 
        alignItems:'center',
        flexDirection:'column',
        overflow: 'hidden' 
    },
    buttonsSeccion:{
        gap: isMobile ? '2%' : '1%' ,
        justifyContent: 'center' ,
        marginBottom:isMobile?'200px':'0px',
        display:'flex',
        flexWrap: 'wrap',
        width: '100%',
        overflowY:'auto',  
    },
    footer:{
        zIndex:1000,
        height:isMobile? '10%':'12%',
        background:'#2aac9cff',
        position:'fixed',
        bottom:0,
        left:0,
        right:0,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center' 
    }
}
 