
import React, { useState,useContext,useEffect } from 'react'
import { TimelineContext } from '../../../helpers/Context';
import { isMobile,isDesktop,isIPad13 } from 'react-device-detect';


const Indicator = ({options,callBack,onClick,forceDisable,forceStop }) =>{

    const contextTimeline = useContext( TimelineContext );
    const { title, icon, initialState, values:propsValues, type } = options
    const [ status, setStatus ] = useState(initialState) 
    const [ values, setValues ] = useState(propsValues || [])   
    const [typeOfIndeicator, setTypeOfIndicator ] = useState(type)

    const handlerOnClick = () => {  
        if(forceStop) return
        if(forceDisable) return
        if(onClick){ 
            onClick()
        }
    } 
 
    useEffect(()=>{
        setTypeOfIndicator(type) 
    },[type])

    
    useEffect(()=>{
        setValues(propsValues) 
    },[propsValues])

    return (<>
        <div onClick={handlerOnClick}  
        style={{ 
            opacity: forceDisable ? '50%' : '100%',
            width: isIPad13 ?  
                        '31%' : 
                        isMobile ? '47%' : '31%'
        }}
        className={`cursor-pointer bg-white mt-[1%] rounded-[10px] flex flex-col items-center opacity-[${status?'100%':'50%'}]`}>
            <div className="flex p-1 items-center gap-2 justify-between w-full"> 
                <div className="text-sm flex flex-col p-1 justify-center items-between w-4/6 font-[600]">
                    {title} 
                </div>
                <div className="flex items-center justify-center w-2-6 rounded bg-[#fff] w-[40px] h-[40px]">
                    <img src={icon} alt={icon} />
                </div>
            </div>
            <div className="flex p-1 items-center justify-start gap-2 w-full"> 
                <div className="flex p-1 items-center w-full ">
                    {values?.map((item)=>{ 
                        return ( 
                            <div className='flex jusfity-center items-center mr-2 gap-2'>
                                <div className={`min-h-[10px] min-w-[10px]${item.status ? ' bg-green-300':' bg-gray-300'} rounded-full `}></div> 
                                  
                                 
                                 <span className='max-w-[80%] text-clip overflow-hidden ...'>
                                        {item.title}
                                </span>
                            </div>
                        ) 
                    })}  
                </div> 
                
            </div> 
        </div>
    </>) 
}

export default Indicator