import React, { useState } from 'react'
import './home.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-tabs/style/react-tabs.css'
import {Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Header from '../../components/Header/Header'
import NSCardButton from '../../components/NSCardButton/NSCardButton'
import { iconCompresiones, iconDea, iconMonitor, iconNoResponde, iconNotas } from '../../helpers/ImagesCache';
import { isMobile } from 'react-device-detect';
import SimpleTitle from '../../components/SimpleTitle/SimpleTitle';






const Home = () => {  
  const [isMonitorStart, setIsMonitorStart] = useState(false) 
   
  return (
  <div className='body overflow-hidden right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[1024px] items-center justify-center w-[100vw] overflow-hidden'>
    
    <Header options={{}} minimal={true} />
    <div className='p-4 flex flex-col flex-1 bg-[#eaf7f5] flex w-full items-center gap-5'>
        
        <SimpleTitle text='¿Qué vas a simular?' />
        <div className='flex gap-2 w-full w-10/12'>  
            <Link className='w-4/12' to="/advanced"  >
                <NSCardButton
                    options={{ 
                        title:'Soporte vital avanzado', 
                        icon:iconMonitor, 
                    }}
                    /> 
            </Link> 
            <Link className='w-4/12' to="/basic"  >
                <NSCardButton
                    options={{
                        title:'Soporte vital basico + DEA' ,
                        icon:iconCompresiones,
                        iconOptional:iconDea,
                    }}   
                    /> 
            </Link>  
            <Link className='w-4/12' to="/ovace"  >
                <NSCardButton
                    options={{
                        title:'OVACE',
                        icon:iconNoResponde
                    }}   
                    /> 
            </Link>
        </div>
        <Link className='w-10/12' to="/reports"  >
            <NSCardButton
                options={{
                    title:'Informes',
                    icon:iconNotas,
                    horizontal:true
                }}   
            /> 
        </Link>
    </div> 
  </div>
    )
}
export default Home
