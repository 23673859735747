
import React,{ createContext, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import { Home, Basic, Ovace, Advanced, Reports } from './pages'
import ReactGA from 'react-ga'

ReactGA.initialize('G-75FQ87LFHC')

import { GlobalContext,TimelineContext } from './helpers/Context';
import { TimelineDefault } from './helpers/Constants';
const GlobalDefault = { 
  startCase : null
}

const App = () => {
  const [contextGolbal, setContextGolbal] = useState(GlobalDefault)
  const [contextTimeline, setContextTimeline] = useState(TimelineDefault)

 
  return (
     
    <GlobalContext.Provider value={contextGolbal}>
       <TimelineContext.Provider value={contextTimeline}>
        <BrowserRouter>
            <Routes>
              <Route path="*" element={<Home />} />
              <Route path="/" element={<Home />} /> 
              <Route path="/basic" element={<Basic />} />
              <Route path="/ovace" element={<Ovace />} />
              <Route path="/advanced" element={<Advanced />} /> 
              <Route path="/reports" element={<Reports />} /> 
            </Routes>
        </BrowserRouter>
       </TimelineContext.Provider>
    </GlobalContext.Provider>
 
  )
}




export default App