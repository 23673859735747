import { faClose, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

const DialogFinish = ({icon, text, callbackConfirm,callbackCancel,status}) => {
     
    const [isOpen,setIsOpen] = useState(status)
    const handlerClose = () => {
        if(callbackCancel){ 
            callbackCancel()
        }
    }

    const handlerOnConfirm = () => {  
        if(callbackConfirm){ 
            callbackConfirm()
        }
    } 
     
    useEffect(()=>{ 
        setIsOpen(status) 
    },[status])


    
    return (<>
       {
            isOpen && 
            <div className=" flex items-center justify-center absolute left-0 top-0 bg-[#0005] w-full h-[100%]" style={{zIndex:10001}}> 
                
                    <div   className="flex flex-col items-end bg-white"
                        style={{
                            width:isMobile? '95%' : '80%',
                            height:isMobile? '70%' : '80%',
                            padding:isMobile? '10px' : '10px',
                        }} 
                    >
                        <button className="" onClick={handlerClose}> 
                            <FontAwesomeIcon icon={faClose} /> 
                        </button>
                        <div className="flex p-10 gap-3 flex-col w-full h-full items-center justify-center">
                            {
                            icon && 
                            <div className="rounded flex items-center justify-center text-xl bg-[#2aac9c] w-8 h-8 text-white border">
                                <FontAwesomeIcon icon={faStopwatch} /> 
                            </div>
                            }
                            <span className="font-bold text-center text-base">
                                {text} 
                            </span>
                            <div className="flex gap-4"> 
                                <button 
                                    style={{
                                        width:isMobile? '120px' : '140px',
                                        height:isMobile? '40px' : '40px',
                                        border: 'solid 1px #0005', 
                                        color: 'black'
                                    }}
                                    className=" text-base font-bold bg-[#fff] rounded" 
                                    onClick={handlerClose}
                                > 
                                    Cancelar
                                </button>
                                <button 
                                    style={{
                                        width:isMobile? '120px' : '140px',
                                        height:isMobile? '40px' : '40px' ,
                                        border: 'solid 1px #0005',
                                        color:'white'
                                    }}
                                    className="text-base font-bold bg-[#2aac9c] rounded" 
                                    onClick={handlerOnConfirm}
                                > 
                                    Informe
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        }
    </>)
}

export default DialogFinish