import React,{useState,useEffect} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { timerFormat,completeTimerFormat } from "../../../helpers/helpers"
import { isMobile } from "react-device-detect"


const Timer = ({options, onStartTimer, onStopTimer, milliseconds, forceDisable ,forceStop }) =>{
    const {titleOff,titleOn } = options
    const [diff, setDiff] = useState(null)
    const [initial, setInitial] = useState(null) 
    const [start, setStart] = useState(false)
      
    const tick = () => {
        if(forceStop) return
        const temporalDate = +new Date()  
        setDiff(new Date(temporalDate - initial ))
    } 

    const handlerStart =()=>{  
        setStart(true)
        setInitial(+new Date())
        if(onStartTimer){
            onStartTimer({
                startAt: +new Date(), 
                stopAt:  null, 
            })
        }
 
    }

    const handlerStop =()=>{ 
        if(forceStop) return
        setStart(false) 
        if(onStopTimer){
            onStopTimer({
                startAt: initial, 
                stopAt:  initial + diff.getTime(), 
            })
        }
       //alert('se detuvo con el valor initial=' + initial)
    } 

    const handlerOnClick = () => {  
        if(forceStop) return
        if(forceDisable) return
        if(start){
            handlerStop()
        } else {
            handlerStart()
        }  
    } 
    
    useEffect(()=>{
        if(forceStop) return

        if(initial && start){
            requestAnimationFrame(tick)
        }  
    },[initial,start])
    
    useEffect(()=>{
        if(forceStop) return
        if(diff && start){
            requestAnimationFrame(tick)
        }  
    },[diff,start])
     
    useEffect(()=>{
        console.log('')
    },[forceDisable])
    



    return (<>
        <div style={{
                border:start ? "solid 2px #0BAC9D":"solid 2px #0BAC9D00",
                width:isMobile?'180px':'220px',
                opacity: forceDisable ? '50%' : '100%'
            }}
            className="flex cursor-pointer shadow bg-white rounded items-center border  h-[46px] border-2 border-[#0BAC9D]"
            onClick={handlerOnClick}
            >
            <div className="flex p-0 items-center justify-between gap-1 p-1 w-full">
                <div className="flex  items-center text-sm min-w-6/12 w-6/12 max-w-6/12"> 
                    
                    {
                    start? 
                        titleOn?
                            titleOn
                        :
                            'titulo' 
                    : 
                        titleOff?
                            titleOff
                        :
                            'titulo'
                    }
                </div>
                <div style={{color:start?"white":"black",background:start?"#0BAC9D":"#00ffaa22", width: milliseconds ? '60%': '50%'}} className="flex items-center rounded gap-2  p-1 justify-between min-w-6/12 w-6/12 max-w-6/12">
                    <FontAwesomeIcon icon={faStopwatch} />
                    <span style={{
                        width:isMobile?'60px':'80px'
                    }}
                        className="text-xl flex items-center w-[80px]">
                        { 
                        milliseconds ? 
                            isMobile? 
                                timerFormat(diff) : 
                                completeTimerFormat(diff) : 
                            timerFormat(diff) }
                         
                    </span>
                </div>
            </div>
        </div>
    </>) 
}

export default Timer