import { faClose, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { iconAdulto, iconNino } from "../../helpers/ImagesCache";

const DialogPediatrico = ({icon, text, callbackConfirm,callbackCancel,status}) => {
     
    const [isOpen,setIsOpen] = useState(status)
    const handlerClose = () => {
        if(callbackCancel){ 
            callbackCancel()
        }
    }

    const handlerOnConfirm = () => {  
        if(callbackConfirm){ 
            callbackConfirm()
        }
    } 
     
    useEffect(()=>{ 
        setIsOpen(status) 
    },[status])


    
    return (<>
       {
            isOpen && 
            <div className="flex items-center justify-center absolute left-0 top-0 bg-[#0005] w-full h-full" style={{zIndex:10001}}> 
                
                    <div className="w-[80%] h-[80%] p-4 flex flex-col items-end bg-white">
                        <button className="" onClick={handlerClose}> 
                            <FontAwesomeIcon icon={faClose} /> 
                        </button>
                        <div className="flex p-10  flex-col w-full h-full items-center justify-center gap-5">
                            
                            <h3 className="font-bold text-center">
                                {text} 
                            </h3>
                            <div className="flex gap-4"> 
                                <button 
                                    className="flex flex-col justify-center items-center border-1 border border-[#00055]  hover:text-white hover:bg-[#2aac9c] w-24 text-black text-sm font-bold bg-[#fff] rounded p-4" 
                                    onClick={handlerClose}>  
                                     <span>Pediatrico</span>
                                    <img src={iconNino} alt={iconNino} />
                                     
                                </button>
                                <button 
                                    className="flex flex-col justify-center items-center  hover:text-white hover:bg-[#2aac9c] border-1 border border-[#00055] w-24 text-black text-sm font-bold bg-[#fff] rounded p-4" 
                                    onClick={handlerOnConfirm}> 
                                     <span>Adulto</span>
                                     <img src={iconAdulto} alt={iconAdulto} />
                                    
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        }
    </>)
}

export default DialogPediatrico