import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCircleArrowRight, faClose, faRectangleXmark, faRightFromBracket, faRightToBracket } from '@fortawesome/free-solid-svg-icons' 
import { Link } from "react-router-dom"; 
import { iconNoResponde, mainLogo } from "../../helpers/ImagesCache";
const Hamburger = () => {

    const [isOpenMenu,setIsOpenMenu] = useState(false)
    
    const handlerOpenMenu = () => {
        setIsOpenMenu(!isOpenMenu)
    }
     
    const LateralMenu = () =>{

        const MenuItem = ({ title, icon, to, between }) => {
            return(<>
                <Link to={to} 
                    className='w-full p-2 flex gap-2 hover:bg-[#00ff9955]'  
                    style={{
                        justifyContent: between ? 'between' : 'flex-start',
                        alignItems:'center'
                    }}
                >
                    <span className="font-[500] w-11/12 text-left text-base">
                        {title}
                    </span>
                    <span className="w-1/12">
                        {
                            icon &&
                            <FontAwesomeIcon icon={icon} />
                        }
                    </span>
                </Link>
            </>)
        }
        return (<>
            <div className="absolute flex left-0 top-0 bg-[#0005] w-full h-full" style={{zIndex:10001}} > 
                <div  className="w-[auto] h-full flex flex-col items-end p-4 gap-5 justify-between bg-white">
                    <div className=" w-full h-full"> 
                        <div className='flex justify-between w-full mb-5 items-center'>
                            <img src={mainLogo} alt={'LOGO'} className="w-[80%] h-[80%]" />
                            <FontAwesomeIcon onClick={handlerOpenMenu} icon={faClose} />
                        </div>
                        <div className={`border-2 border-solid w-full`} />
                        <MenuItem title={'Inicio'} to={'/'} icon={null} />
                        <MenuItem title={'Simulador Basico'} to={'/basic'} icon={null} />
                        <MenuItem title={'Simulador OVACE'} to={'/ovace'} icon={null} />
                        <MenuItem title={'Simulador Avanzado'} to={'/advanced'} icon={null} />
                        <MenuItem title={'Informes'} to={'/reports'} icon={null} />
                        <MenuItem title={'Configuraciones'} to={'/'} icon={null} />
                    </div>
                    <div className="border-t w-full flex ">
                        <MenuItem title={'Cerrar sesion'} to={'/'} icon={faRightFromBracket} between={true} />
                    </div> 
                </div> 
                <div
                    className="bg-[#0001]" 
                    style={{width:'calc(100vw - 200px)'}} 
                    onClick={handlerOpenMenu}
                    >

                </div>
            </div>
        </>)
    }

    return (<> 
        <div className="min-w-[40px]"> 
            {
                !isOpenMenu &&   
                
                <FontAwesomeIcon onClick={handlerOpenMenu} icon={faBars} />
            }
            {
                isOpenMenu &&  
                <LateralMenu />
            }
        </div>
    </>)
}
export default Hamburger