import React, { useContext, useEffect, useState } from 'react'
import './reports.css'
import Header from '../../components/Header/Header'
import NSListItem from '../../components/NSButton/Types/ListItem'
import {  iconInforme, iconNotas } from '../../helpers/ImagesCache';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowDown, faArrowLeft, faArrowUp, faRefresh, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { BASEURLAPI } from '../../helpers/Constants';
import { TimelineContext } from '../../helpers/Context';
import { timerFormat } from '../../helpers/helpers';
import Timeline from '../../components/Timeline/Timeline';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
const Reports = () => {   
    const contextTimeline = useContext( TimelineContext );
    const [currentReport, setCurrentReport ] = useState(0)
    const [selectedReport, setSelectedReport ] = useState({})
    const [userId, setUserId ] = useState(2)
    const [reports, setReports] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isFullReport, setIsFullReport] = useState(false)

    const handlerFullReport = () => {
        setIsFullReport(!isFullReport)
    }
    const ListOfReports = () => { 
        return (
            <div className='flex flex-col  w-full'>   
                {  reports.length > 0 ? 
                    reports.map(
                        (item)=> 
                            <NSListItem  
                                key={'reports-' + item.id}
                                options={item}  
                                onClick={ (value) => viewReport(value) } 
                                onDelete={deleteCase}
                             /> 
                    ) :
                    <span className='ml-10 text-xl text-[#f009] font-bold'>
                        { 
                            isLoading ? 
                            'Cargando datos':
                            'No hay Informes creados'
                        } 
                    </span>
                } 
            </div>
        )
    }
    const CurrentReport = () => {
        if(currentReport == 0) return (<></>)
        return (
            <>
            
            <div 
                style={{
                    height: isFullReport ? 'auto' : '30vh',
                    maxHeight: isFullReport ? 'auto' : '30vh'
                }} 
                className='flex flex-col  w-[100%] h-[auto] overflow-hidden'>   
                { 
                    selectedReport?.timeline?.history?.map(
                        (item, index)=> 
                            ( 
                            <div key={'report-selected-'+index} className={` h-11/12 w-full cursor-pointer bg-white hover:bg-[#0008] hover:text-[#fff]  flex flex-col items-center `}>
                                <div  className={`text-center flex  items-center gap-0 justify-between h-full w-full flex items-between`}> 
                                    <div className="text-sm text-left flex  p-1 justify-start items-center w-4/6 font-[500] gap-4">
                                        <span className='text-[#2aac9c] bg-[#0001] text-sm font-[600] p-1'>
                                            {timerFormat( new Date(item.time))}
                                        </span> 
                                        <span className='text-xl'>
                                            {item.title}
                                        </span> 
                                    </div> 
                                </div> 
                            </div>
                             )
                    ) 
                } 
                
            </div>
            <div className=' cursor-pointer w-full h-10 p-6 flex items-center justify-center text-2xl bg-[#0002]' onClick={handlerFullReport}>
                    {
                        isFullReport? 
                        <div className='flex gap-10 items-center'>
                            <FontAwesomeIcon icon={faArrowUp} />
                            <span>Minimizar</span>
                            <FontAwesomeIcon icon={faArrowUp} />
                        </div>
                         :
                        <div className='flex gap-10 items-center'>
                            <FontAwesomeIcon icon={faArrowDown} /> 
                            <span>Mostrar todo</span>
                            <FontAwesomeIcon icon={faArrowDown} /> 
                        </div>
                    }
            </div>
            </>
        )
    }
    const viewReport = (reportId) => {
        setCurrentReport(reportId)
    }
    const goToRoot = () => {
        setCurrentReport(0)
    }
    const getAllReports = () => {
        
        setIsLoading(true)
        const url = `${BASEURLAPI}/getCases/${userId}`
        axios.get(url,null,{
            headers:{
                "Access-Control-Allow-Origin":"*"
            }
        })
        .then((response)=>{
            if(response.status == 200){ 
                setReports(response.data.data)
                console.log(response.data.message)
            }
            setIsLoading(false)
        })
        .catch((error)=>{
            console.log('hubo un error',error)
            setIsLoading(false)
        })
    }
    const deleteCase = (id) => {
        const report = reports.find(a=> a.id == id )
        const sure = confirm(`Desea eliminar el reporte ${report?.name}`)
        if(sure){ 
            const url = `${BASEURLAPI}/deleteCase/${userId}/${id}`
            
            axios.get(url)
            .then((response)=>{
                if(response.status == 200){
                    console.log(response.data) 
                } 
                getAllReports()
            })
            .catch((error)=>{
                console.log('hubo un error',error) 
                getAllReports()
            })
        }
    }

    useEffect(()=>{
        getAllReports()
    },[userId])

    useEffect(()=>{
        const reporteActual = reports.find((item)=>item.id == currentReport)
        
        console.log(reporteActual)
        if(reporteActual?.id > 0){ 
            setSelectedReport( {
                ...reporteActual, 
                "timeline": JSON.parse(reporteActual?.timeline)
            })
        }

    },[currentReport])

    return (
        <div className='overflow-x-hidden right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[750px] items-center justify-start w-[100vw]'>
            
            <Header options={{}} minimal className='' />
            
            <div className=' z-[0] p-4  flex flex-col  bg-[#fff] flex w-full items-center justify-start gap-5 '>
                
                <div className='flex w-full items-center justify-between gap-4 font-[600] text-[#0007]'>
                    <div className='flex w-full items-center gap-4'>
                        { currentReport > 0 && <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={goToRoot} /> }
                        <img src={iconInforme} alt={iconInforme} />
                        <span>INFORMES</span> 
                    </div>
                    <div className='flex gap-4 cursor-pointer items-center justify-center h-full w-10 text-2xl text-[#2aac9c]' >
                        { currentReport > 0 && 
                            <FontAwesomeIcon 
                                className='hover:bg-red-100 cursor-pointer hover:text-red-500 flex text-black' 
                                icon={faTrashAlt} 
                                onClick={()=>deleteCase(selectedReport?.id)}/> 
                        }
                        <FontAwesomeIcon 
                            className='transform hover:rotate-[360deg] transition-ease  transition-[2s] ' 
                            icon={faRefresh } 
                            onClick={getAllReports} />
                    </div>
                </div>
                
                { currentReport == 0 && <ListOfReports /> } 
                { currentReport > 0 && 
                    ( 
                    <div className='w-full border-b-2 border-b-solid border-b-gay-500 pb-2'>
                        <span className='text-xl font-[500]'>
                            {selectedReport?.name}
                        </span>
                    </div> 
                    ) 
                } 
                <div className='w-full relative'>
                    <CurrentReport /> 
                </div>
                {
                    currentReport > 0 &&
                    <div className="p-4 w-full flex flex-col mt-2 relative bg-[#0f01]">
                        <h1 className="text-2xl font-bold mb-6">Grafica de tiempo</h1>
                        <div className='my-5'>
                            <Timeline 
                                data={selectedReport?.timeline?.history} 
                                titles={["Inicio caso","Fin Caso"]} 
                                filter={['startCase',"stopCase"]} 
                                showTitle
                                /> 
                        </div> 
                        <div className='my-2'>
                            <Timeline 
                                data={selectedReport?.timeline?.history} 
                                titles={["PCR","RCE"]} 
                                filter={['PCR',"RCE"]} 
                                type={'RCE'}
                                showTitle
                                />
                        </div> 
                        <div className='my-3'>
                            <Timeline 
                                data={selectedReport?.timeline?.history} 
                                titles={["Desfibrilacion"]} 
                                filter={['defibrillation']}  
                                bg={'gray'} 
                                ballColor={'yellow'}
                                borderColor={'black'}
                                borderWidth={'2px'}
                                icon={faBolt}
                                ballSize={'25px'}
                                />
                        </div> 
                        <div className='my-5'>
                            <Timeline 
                                data={selectedReport?.timeline?.history}  
                                titles={["C","V"]} 
                                filter={['compressions',"vents"]} 
                                showTitle
                            /> 
                        </div> 

                    </div>
                }
            </div>
            
        </div>
    )
}
export default Reports
