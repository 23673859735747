
import React from "react"

const SimpleTitle = ({text}) => {


    return (
        <h2 className='text-2xl font-[500] my-2'>
            {text}
        </h2>
    )
}

export default SimpleTitle