import React, { useEffect, useState } from 'react' 
import Hamburger from '../Hamburger/Hamburger'
import { ilustration, mainLogo } from '../../helpers/ImagesCache'
import { isMobile,isDesktop, isTablet, isIPad13 } from 'react-device-detect'
const Header = ({logo,options,minimal,className}) =>{
    const { elements } = options
    const [components, setComponents] = useState([])

    const Elements = () => { 
        return (<> {components.map((item)=>item)}</>)
    }
 
    useEffect(()=>{
        if(elements){
            setComponents(elements)
        }
    },[elements])
 
    return (<>
    <div  style={styles.main(minimal)} className={className?className:''} >  
        <div  style={styles.container} className="px-6 py-2 " >
            <div className="w-1/12 flex h-full items-center justify-center text-center text-2xl">
                <Hamburger />
            </div>  
            <div className='flex justify-center w-10/12'>
                <img 
                    src={ logo? logo : mainLogo } 
                    alt={'LOGO'} 
                    className='max-w-[250px]' 
                    /> 
            </div>
            <div className="flex">
            </div>
        </div> 
        { 
            !minimal  && 
            <div style={styles.elements}
                className="w-1/12 z-10 flex items-center justify-center gap-4 w-full">  
                <Elements /> 
            </div> 
        }
        { 
            !minimal && 
            <img  className='absolute top-0 right-0 z-1' 
                src={ilustration} 
                alt='ilustration' 
                style={styles.ilustracion}
                />
        }
    </div>
    </>)
}
export default Header


const styles = {
    main : (minimal)=> {
        return { 
            zIndex: 100, 
            height: isIPad13 ? 
                        minimal ? '70px' : '150px' : 
                        isDesktop ? '80px': '180px',
            justifyContent: minimal ? 'center' : 'space-between',
            minWidth: 330,
            maxWidth: isTablet ? 
                        '100%' : 
                        isDesktop ? '1280px' : '700px',
            width: '100%',
            display: 'flex',
            background: 'white',
            flexDirection: 'column',
        } 
    },
    container :{
        paddingTop: isMobile? '10px' : '10px',
        paddingBottom: isMobile? '10px' : '10px',
        paddingRight: isMobile? '40px' : '20px',
        paddingLeft: isMobile? '40px' : '20px',
        height: '100%',
        width: '100%', 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',  
    },
    elements:{
        paddingTop: isMobile? '10px' : '10px',
        paddingBottom: isMobile? '10px' : '20px',
        paddingRight: isMobile? '10px' : '20px',
        paddingLeft: isMobile? '10px' : '20px',
    },
    ilustracion: { 
        width:'auto', 
        height:'20vh', 
        zIndex:0,
        right:'calc(100% - 1280px)'
    }

}

