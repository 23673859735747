import React, { useState,useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { PRIMARY_COLOR } from '../../../helpers/Constants'
import { timerFormat,counterFormat } from "../../../helpers/helpers"
import { isMobile,isDesktop, isTablet, isIPad13 } from 'react-device-detect'


const Counter = ({ options, onStartTimer, onStopTimer,list,forceDisable,forceStop }) =>{
    const { titleOff,titleOn,titleStart,icon, type, initialState, disableInitialTimer, onlyCounter } = options
    const [diff, setDiff] = useState(null)
    const [initial, setInitial] = useState(null) 
    const [start, setStart] = useState(false)
    const [counter, setCounter] = useState(0)
    const [hiddenCounter, setHiddenCounter] = useState(disableInitialTimer)
    const [showTitle, setShowTitle ]  = useState(titleStart)
    const [firstTime, setFirstTime ]  = useState(true) 
      
    const tick = () => {
        const temporalDate = +new Date()  
        setDiff(new Date(temporalDate - initial ))
    } 

    const handlerStart =()=>{ 
        if(forceDisable) return
        setStart(true)
        setHiddenCounter(false)
        setInitial(+new Date())
        if(onStartTimer){
            onStartTimer({
                startAt: +new Date(), 
                stopAt:  null, 
            })
        }
        setShowTitle(titleOn)
    }

    const handlerStop =()=>{ 
        setStart(false) 
        if(onStopTimer){
            onStopTimer({
                startAt: initial, 
                stopAt:  initial + diff.getTime(), 
            })
        }
    } 
    const handlerRestart =()=>{ 
        if(forceDisable) return 
        setInitial(+new Date())
        if(onStopTimer){
            onStopTimer({
                startAt: initial, 
                stopAt:  initial + diff.getTime(), 
            })
        }
    }

    const handlerOnClick = () => {  
        if( !forceStop ){
            if(start){
                if(type){
                    if(type=='restart'){
                        handlerRestart() 
                        setCounter(list.length + 1)
                    }
                } else { 
                    handlerStop()
                }
            } else {
                handlerStart()
                setCounter(list.length + 1)
            }  
        }
    } 
     
    useEffect(()=>{
        if( !forceStop ){
            if(initial && start){
                requestAnimationFrame(tick)
            }  
        }
    },[initial,start])
    
    useEffect(()=>{
        if( !forceStop ){
            if(diff && start){
                requestAnimationFrame(tick)
            } 
        }
    },[diff,start])

    useEffect(()=>{
        if( !forceStop ){
            if( !start ){
                setFirstTime(false)
                
            }
            if(firstTime == false){
                if(start){
                    setShowTitle(titleOn)
                } else {
                    setShowTitle(titleOff)
                } 
            }  
        }
        
    },[start])

    useEffect(()=>{
        if( forceStop ){
            setDiff(null)
            setInitial(null)
            setStart(false)
        } 
    },[forceStop])
    

    return (<>
        <div 
            onClick={handlerOnClick}  
            style={{
                opacity: forceDisable ? '50%':'100%',
                border: !start ? 'solid 2px #0000' : `solid 2px ${PRIMARY_COLOR}`,
                width: isIPad13 ?  
                        '31%' : 
                        isMobile ? '47%' : '31%'
            }} 
            className={`cursor-pointer bg-white mt-[1%] rounded-[10px] flex flex-col items-center `}>
            <div className="flex p-1 items-center gap-2 justify-between w-full"> 
                <div className="text-sm flex flex-col p-1 justify-center items-between w-4/6 font-[600]">
                    {showTitle} 
                </div>
                <div style={{opacity: !start ? '50%' : '100%'}}  className="flex items-center justify-center w-2-6 rounded bg-[#fff] w-[40px] h-[40px]">
                    <img src={icon} alt={icon} />
                </div>
            </div>
            
            <div style={{justifyContent:onlyCounter?'flex-end':'space-between'}} className="flex p-1 items-center gap-2 w-full"> 

                {
                    !hiddenCounter &&
                    <>
                        {
                            !onlyCounter && 
                        
                            <div style={{color: !start ? '#0005' : '#000'}} className="flex p-1 items-center w-4/6 gap-2">
                                <span style={{color: !start ? '#0005' : '#fff',background: !start ? 'rgb(221,245,255)' : `${PRIMARY_COLOR}`  }} className='bg-[rgb(221,245,255)] p-[2px] px-1'> 
                                    <FontAwesomeIcon icon={faStopwatch} />
                                </span>
                                <span className='text-2xl'>
                                    {timerFormat(diff)}
                                </span>
                            </div>
                        }
                        <div style={{color: !start ? '#0005' : '#000'}} className="flex items-center w-2-6 text-3xl">
                            <span className='text-3xl'>
                                {counterFormat(counter)}
                                </span>
                        </div>
                    </>
                }
            </div> 
        </div>
    </>) 
}

export default Counter