import React,{useEffect, useState} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { PRIMARY_COLOR } from "../../../helpers/Constants"

const Switcher = ({options,onClick,forceDisable, forceStop}) =>{

    const { title: titleProps, titleActive: titleActiveProps, subtitleActive: subtitleActiveProps, subtitle: subtitleProps, initialState } = options
    const [title, setTitle] = useState(titleProps  || '') 
    const [subtitle, setSubtitle] = useState(subtitleProps  || '') 
    const [active, setActive] = useState(initialState) 
    const handlerCheck = () => {
        if(forceStop) return
        if(forceDisable) return
        setActive(!active) 
        if(onClick){
            onClick(!active)
        }
    }

    useEffect(()=>{
        if(forceStop) return
        if(forceDisable) return
        if( active ){
            if(subtitleActiveProps){ 
                setSubtitle(subtitleActiveProps)
            }
            if(titleActiveProps){ 
                setTitle(titleActiveProps)
            } 
        } else {
            setSubtitle(subtitleProps)
            setTitle(titleProps)
        }

    },[active])
    
    return (<>
        <div 
            style={{ opacity: forceDisable ? '50%' : '100%'}}
            className="flex  items-center h-[40px] cursor-pointer" 
            onClick={handlerCheck}
        >
            <div className="flex p-1 items-center gap-2"> 
                <div style={{backgroundColor:active?PRIMARY_COLOR:'white',transition:'ease-in'}} className={`flex ${active?`justify-end text-white`:'justify-start text-black'}   rounded-full bg-[#fff] border p-1 w-[40px] `}>
                    <FontAwesomeIcon icon={active?faCircleCheck :faCircleXmark} /> 
                </div>
                <div className="flex flex-col text-sm p-1 items-start">
                    <span className="font-[600]">
                        {title}
                    </span> 
                    {
                        subtitle && 
                        <span 
                            className="text-[10px] font-[600]"
                            style={{color:active?PRIMARY_COLOR:'#0005',}}
                        >
                            {subtitle}
                        </span>
                    }
                </div>
            </div>
        </div>
    </>) 
}

export default Switcher