
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const Timeline = ({ data, type, filter, bg, showTitle,titles,ballColor,borderColor,borderWidth,icon,ballSize }) => {
    const [startTimeline, setStartTimeline] = useState(0)
    const [finishTimeline, setfinishTimeline] = useState(0)
    const [ filtered, setFiltered ] = useState(filter || [])


    const getTitle = ( item ) => {

        const index = filter?.indexOf(item)
         
        if(titles){
            return titles[index]
        }
        return ''
    }

    const getTimelinePosition = ( time, start, finish ) =>{
        const calculo = parseInt(finish) - parseInt(start)  //cantidad total
        const recorrido = parseInt(time) - parseInt(start) //cantidad recorrida
        const resultado = ((recorrido>0?recorrido:1)  / calculo) * 100 
        //console.log(`${calculo} ${recorrido} ${resultado}`) 
        return resultado.toFixed(2)
    }

    useEffect(()=>{
        if(data?.length > 0 ){ 
            const startTime = data.find( item => item.object == "startCase" )
            const stopTime = data.find( item => item.object == "stopCase" )
            setStartTimeline(parseInt(startTime?.time))
            setfinishTimeline(parseInt(stopTime?.time))
             
        }
    },[data])

    return (
        <div style={{background:bg?bg:'#2aac9c55'}} className="relative flex mx-12 flex-row items-center rounded-full h-[2px] my-4"> 
        {/* Renderizamos cada punto en la línea */}
        {data?.map((item, index) => { 
            const exist = filtered.find( a => a == item.object ) 
            const isStartCase = item.object == "startCase" 
            const isStopCase = item.object == "stopCase" 
            const title = getTitle(item?.object)
            if(exist){ 
                const getPosition = getTimelinePosition( item?.time, startTimeline, finishTimeline )
                const getPCRPosition = getTimelinePosition( data[data.indexOf( data.find(a=>a.object=='PCR')) ]?.time, startTimeline, finishTimeline )
                const getRCEPosition = getTimelinePosition( data[data.indexOf( data.find(a=>a.object=='RCE')) ]?.time, startTimeline, finishTimeline )
                const totalRecorrido = getRCEPosition - getPCRPosition
                return ( 
                    <div key={item?.time} 
                        style={{
                            left:`${getPosition}%`,
                            width: type =='RCE' ? `${(item.object=='PCR'? (totalRecorrido) : 0)}% `:` ${ballSize?ballSize:'16px'} ` , 
                            height:type == 'RCE' ? `16px `:` ${ballSize?ballSize:'16px'} ` 
                        }} 
                        className="absolute flex items-center justify-start w-auto"
                        > 
                         {  
                            showTitle && title != "" &&
                            <div className="absolute w-[200px] h-2 transform -left-6 -top-6">
                                <span>{ title }</span>
                            </div>
                         }   
                          
 
                        {/* Punto */}
                        <div  className="z-10 rounded-full flex items-center justify-center" 
                            style={ { 
                                background:isStartCase ? 'white' : ballColor ? ballColor: '#2aac9c', 
                                border: type =='RCE' ? 'none':`${borderWidth?borderWidth:'4px'} solid ${borderColor ? borderColor:'#2aac9c'}`, 
                                width: type =='RCE' ? '100%': ` ${ballSize?ballSize:'16px'} `, 
                                height:type == 'RCE' ? '16px':` ${ballSize?ballSize:'16px'} ` 
                            }}   
                            >
                                {icon && <FontAwesomeIcon icon={icon} /> }
                                
                        </div> 
                        {/* Contenido del punto */}
                        {/* 
                            <div className="ml-4 p-4 bg-white rounded-lg shadow-md w-64">
                                <h4 className="font-bold text-lg">{item.title}</h4>
                                <p className="text-sm text-gray-500">{new Date(item.time).toLocaleString()}</p>
                                <p className="text-sm">{item.object}</p>
                            </div> 
                        */}
                    </div>
                    )
            } 
            return <></>
            
        })}
        </div>
    );
};

export default Timeline;